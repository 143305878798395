<template>
  <span class="badge bg-info p-1" style="width: 65px; white-space: pre-line">{{
    name
  }}</span>
</template>
<script>
export default {
  props: ["name"],
};
</script>
